// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-big-idea-js": () => import("./../src/pages/big-idea.js" /* webpackChunkName: "component---src-pages-big-idea-js" */),
  "component---src-pages-hire-us-js": () => import("./../src/pages/hire-us.js" /* webpackChunkName: "component---src-pages-hire-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stories-js": () => import("./../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-work-index-js": () => import("./../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-latex-js": () => import("./../src/pages/work/latex.js" /* webpackChunkName: "component---src-pages-work-latex-js" */),
  "component---src-pages-work-osu-js": () => import("./../src/pages/work/osu.js" /* webpackChunkName: "component---src-pages-work-osu-js" */),
  "component---src-pages-work-surgere-js": () => import("./../src/pages/work/surgere.js" /* webpackChunkName: "component---src-pages-work-surgere-js" */),
  "component---src-pages-work-work-js": () => import("./../src/pages/work/work.js" /* webpackChunkName: "component---src-pages-work-work-js" */)
}

